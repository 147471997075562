<script setup>
 import { getEnv } from '@/composables/helpers/getEnv';

 const { hit } = defineProps({
  hit: {
   type: Object,
   required: true,
  },
 });

 const description = computed(() => {
  const hitDescription = hit?.seo?.description || hit.description;
  if (!hitDescription) return false;

  const regex = /(<([^>]+)>)/gi;
  const clean = hitDescription.replace(regex, '');

  const chop = 200;
  const delta = 15;
  if (clean.length > chop) {
   const slice = clean.slice(0, chop + delta);
   const chunk = slice.split(' ').slice(0, -1).join(' ');
   return chunk + '...';
  } else {
   return clean;
  }
 });

 const notIframe = !useRoute().path.includes('/iframe');
 const clientFrontEnd = getEnv('clientFrontEnd');
</script>

<template>
 <MessLink :href="hit.url" class="block-algolia-hit" v-if="notIframe">
  <strong class="h6" v-if="hit.model">{{ hit.model }}</strong>
  <h3 v-if="hit?.seo?.title || hit.title">{{ hit?.seo?.title || hit.title }}</h3>
  <MessHtml tag="span" v-if="description" :html="description" class="html" />
 </MessLink>
 <a :href="clientFrontEnd + hit.url" class="block-algolia-hit" target="_top" v-else>
  <strong class="h6" v-if="hit.model">{{ hit.model }}</strong>
  <h3 v-if="hit?.seo?.title || hit.title">{{ hit?.seo?.title || hit.title }}</h3>
  <MessHtml tag="span" v-if="description" :html="description" class="html" />
 </a>
</template>

<style lang="scss">
 html {
  body {
   .block-algolia-hit {
    user-select: auto;

    transition: 250ms ease-in-out;

    &:hover {
     opacity: 0.5;
    }

    strong {
     color: var(--red, #c00);
     /* Utility/Button Label LG */
     font-size: 14px;
     font-style: normal;
     font-weight: 900;
     line-height: 123.1%; /* 17.234px */
     letter-spacing: 1.61px;
     text-transform: uppercase;
     margin-bottom: 5px;
     display: inline-block;
    }
    h3 {
     color: var(--black, #000);
     font-size: 20px;
     font-style: normal;
     font-weight: 600;
     line-height: 135.6%; /* 27.12px */
     letter-spacing: 0.3px;
     text-transform: none;
     margin-bottom: 0;
    }
   }
  }
 }
</style>
import type { getEnv } from '~/composables/helpers/getEnv';
